import React from 'react';
import logo from './logo.svg';
import './App.css';
import NotesButton from './NotesButton';
// import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import NoteBook from './NoteBook/notebook';
import Home from './Home';
import path from 'path';

export interface TaskTypeOption {
  value: string;
  label: string | React.ReactElement;
  isDisabled?: boolean;
  isFixed?: boolean;
}

const typeOptions: Array<TaskTypeOption> = [
  { value: 'REQUIRED', label: 'Required' },
  { value: 'OPTIONAL', label: 'Optional' },
  { value: 'BONUS', label: 'Bonus' },
];

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/notebook',
    element: <NoteBook />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
